import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/roleManagement/getList',
    method: 'get',
    params,
  })
}

export function doEdit(data) {
  return request({
    url: '/roleManagement/doEdit',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/roleManagement/doDelete',
    method: 'post',
    data,
  })
}

export function doGetPerms(data) {
  return request({
    url: '/roleManagement/getPerms',
    method: 'get',
    params: data,
  })
}

export function doSetPerms(data) {
  return request({
    url: '/roleManagement/setPerms',
    method: 'post',
    data,
  })
}

export function getRole(data) {
  return request({
    url: '/roleManagement/getRole',
    method: 'post',
    data,
  })
}
