<template>
  <div class="menu-management-container">
    <el-card shadow="hover">
      <vab-query-form>
        <vab-query-form-top-panel :span="12">
          <el-button
            v-if="$perms('setting_menu_insert')"
            icon="el-icon-plus"
            type="primary"
            @click="handleEdit"
          >
            添加
          </el-button>
        </vab-query-form-top-panel>
      </vab-query-form>
      <el-table
        v-loading="listLoading"
        border
        :data="list"
        default-expand-all
        row-key="path"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column label="id" prop="id" show-overflow-tooltip />
        <el-table-column
          v-if="false"
          label="parentId"
          prop="parentId"
          show-overflow-tooltip
        />
        <el-table-column label="标题" prop="meta.title" show-overflow-tooltip />
        <el-table-column label="类型" prop="menutype" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>
              <el-tag v-if="scope.row.menutype == '1'">菜单</el-tag>
              <el-tag v-if="scope.row.menutype == '2'" type="warning">
                按钮
              </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="name" prop="name" show-overflow-tooltip />
        <el-table-column label="路径" prop="path" show-overflow-tooltip />
        <el-table-column label="是否隐藏" show-overflow-tooltip>
          <template #default="{ row }">
            {{ row.meta.hidden ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          label="是否隐藏当前节点"
          show-overflow-tooltip
          width="100"
        >
          <template #default="{ row }">
            {{ row.meta.levelHidden ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          label="vue文件路径"
          prop="component"
          show-overflow-tooltip
        />
        <el-table-column label="重定向" show-overflow-tooltip>
          <template #default="{ row }">
            {{ row.redirect ? row.redirect : '无' }}
          </template>
        </el-table-column>
        <el-table-column label="图标" show-overflow-tooltip>
          <template #default="{ row }">
            <vab-icon v-if="row.meta && row.meta.icon" :icon="row.meta.icon" />
          </template>
        </el-table-column>
        <el-table-column label="是否固定" show-overflow-tooltip>
          <template #default="{ row }">
            {{ row.meta && row.meta.noClosable ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="是否无缓存" show-overflow-tooltip width="120">
          <template #default="{ row }">
            {{ row.meta && row.meta.noKeepAlive ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="badge" show-overflow-tooltip>
          <template #default="{ row }">
            <el-tag
              v-if="row.meta && row.meta.badge"
              effect="dark"
              type="danger"
            >
              {{ row.meta.badge }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="dot" show-overflow-tooltip>
          <template #default="{ row }">
            {{ row.meta && row.meta.dot ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip width="185">
          <template #default="{ row }">
            <el-button
              v-if="$perms('setting_menu_update')"
              type="primary"
              @click="handleEdit(row)"
            >
              <vab-icon icon="edit-2-line" />
              编辑
            </el-button>

            <el-button
              v-if="$perms('setting_menu_delete')"
              type="danger"
              @click="handleDelete(row)"
            >
              <vab-icon icon="delete-bin-6-line" />
              删除
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </el-card>

    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { doDelete, getTree } from '@/api/menuManagement'
  import Edit from './components/MenuManagementEdit'
  import { isNull } from '@/utils/validate'

  export default {
    name: 'MenuManagement',
    components: { Edit },
    data() {
      return {
        // 0 根目录
        defaultNode: '0',
        tmpTreeData: {},
        data: [],
        defaultProps: {
          children: 'children',
          label: 'label',
        },
        list: [],
        listLoading: true,
      }
    },
    async created() {
      const {
        data: { list },
      } = await getTree()
      this.data = list
      await this.fetchData()
    },
    methods: {
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ id: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      async fetchData(role) {
        this.listLoading = true
        const {
          data: { list },
        } = await getTree({ role })
        this.list = list
        this.listLoading = false
      },
      //  刷新数据
      refreshNodeBy(id) {
        if (isNull(this.tmpTreeData[id])) {
          this.fetchData()
        } else {
          // 通过节点id找到对应树节点对象
          let tree = this.tmpTreeData[id].tree
          let treeNode = this.tmpTreeData[id].treeNode
          let resolve = this.tmpTreeData[id].resolve
          this.loadNode(tree, treeNode, resolve)
        }
      },

      //懒加载时触发
      async loadNode(tree, treeNode, resolve) {
        // 获得树数据
        const { data } = await getTree({ parentId: tree.id })
        // 2021-06-07 暂时先重载所有路由 来解决数据冲突问题
        if (!data || data.length === 0) {
          // 重载所有路由
          this.$baseEventBus.$emit('reloadRouterView')
          return
        }
        this.tmpTreeData[tree.id] = { tree, treeNode, resolve }
        resolve(data)
      },
      handleNodeClick({ role }) {
        this.fetchData(role)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.menu-management';
  #{$base}-container {
    padding: 0 !important;
    background: $base-color-background !important;
  }
</style>
