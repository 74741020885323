import Vue from 'vue'
import App from './App'
import $perms from '@/utils/perms'
import i18n from './i18n'
import store from './store'
import router from './router'
import '@/vab'
import JsonExcel from 'vue-json-excel'
Vue.component('DownloadExcel', JsonExcel)
/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
import { baseURL, pwa } from './config'
import { isExternal } from '@/utils/validate'

if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
  const { mockXHR } = require('@/utils/static')
  mockXHR()
}

if (pwa) require('./registerServiceWorker')

Vue.config.productionTip = false
Vue.use($perms)

new Vue({
  el: '#app',
  data: {
    eventVue: new Vue(),
    eventVueDevice: new Vue(),
    eventVueService: new Vue(),
    eventVueWork: new Vue(),
    eventVueOv: new Vue(),
  },
  i18n,
  store,
  router,
  render: (h) => h(App),
})
