<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="60%"
    @close="close"
  >
    <el-form
      ref="form"
      :inline="true"
      label-width="140px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="类型" prop="menutype">
        <el-select
          v-model="menutype"
          placeholder="请选择"
          @change="menuTypeChange"
        >
          <el-option
            v-for="item in mentypedata"
            :key="item.key"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="按钮权限"
        :prop="form.menutype == '2' ? 'menu_code' : 'empty'"
      >
        <el-input
          v-model="form.menu_code"
          :disabled="!(form.menutype == '2')"
        />
      </el-form-item>
      <el-form-item label="父级Id" prop="parentId">
        <el-input v-model="form.parentId" />
      </el-form-item>

      <el-form-item
        label="name"
        :prop="form.menutype == '1' ? 'name' : 'empty'"
      >
        <el-input v-model="form.name" :disabled="!(form.menutype == '1')" />
      </el-form-item>
      <el-form-item
        :disabled="!(form.menutype == '1')"
        label="路径"
        :prop="form.menutype == '1' ? 'path' : 'empty'"
      >
        <el-input v-model="form.path" :disabled="!(form.menutype == '1')" />
      </el-form-item>
      <el-form-item
        label="vue文件路径"
        :prop="form.menutype == '1' ? 'component' : 'empty'"
      >
        <el-input
          v-model="form.component"
          :disabled="!(form.menutype == '1')"
        />
      </el-form-item>
      <el-form-item label="重定向" prop="redirect">
        <el-input v-model="form.redirect" :disabled="!(form.menutype == '1')" />
      </el-form-item>
      <el-form-item label="标题" prop="meta.title">
        <el-input v-model="form.meta.title" />
      </el-form-item>
      <el-form-item label="图标" prop="meta.icon">
        <el-popover
          popper-class="icon-selector-popper"
          trigger="hover"
          width="292"
        >
          <template #reference>
            <el-input
              v-model="form.meta.icon"
              :disabled="!(form.menutype == '1')"
            />
          </template>
          <vab-icon-selector @handle-icon="handleIcon" />
        </el-popover>
      </el-form-item>
      <el-form-item label="badge">
        <el-input v-model="form.meta.badge" />
      </el-form-item>
      <el-form-item label="dot">
        <el-switch v-model="form.dot" />
      </el-form-item>
      <el-form-item label="隐藏">
        <el-switch v-model="form.hidden" />
      </el-form-item>
      <el-form-item label="始终显示当前节点">
        <el-switch v-model="form.alwaysShow" />
      </el-form-item>
      <el-form-item label="自定义svg图标">
        <el-switch v-model="form.meta.isCustomSvg" />
      </el-form-item>
      <el-form-item label="固定">
        <el-switch v-model="form.meta.noClosable" />
      </el-form-item>
      <el-form-item label="无缓存">
        <el-switch v-model="form.meta.noKeepAlive" />
      </el-form-item>
      <el-form-item label="不显示当前标签页">
        <el-switch v-model="form.meta.tabHidden" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import VabIconSelector from '@/extra/VabIconSelector'
  import { doEdit } from '@/api/menuManagement'

  export default {
    name: 'MenuManagementEdit',
    components: { VabIconSelector },
    data() {
      return {
        mentypedata: [
          {
            key: '1',
            value: '1',
            label: '菜单',
          },
          {
            key: '2',
            value: '2',
            label: '按钮',
          },
        ],
        menutype: '1',
        form: {
          meta: {
            icon: '',
          },
        },
        rules: {
          parentId: [
            { required: true, trigger: 'blur', message: '请输入父级id' },
          ],
          menutype: [
            { required: true, trigger: 'blur', message: '请输入类型' },
          ],
          menu_code: [
            { required: true, trigger: 'blur', message: '请输入权限code' },
          ],
          name: [{ required: true, trigger: 'blur', message: '请输入name' }],
          path: [{ required: true, trigger: 'blur', message: '请输入path' }],
          component: [
            { required: true, trigger: 'blur', message: '请输入component' },
          ],
          'meta.title': [
            { required: true, trigger: 'blur', message: '请输入标题' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    mounted() {
      // 如果不是每次开启时查询 在created中 有可能会短暂查不到
    },
    methods: {
      handleIcon(item) {
        this.form.meta.icon = item
      },
      showEdit(row) {
        if (!this.form.menutype) {
          this.form.menutype = 1
          this.menutype = '1'
        }

        if (!row) {
          this.title = '添加'
          this.form.meta.isEdit = false
        } else {
          this.title = '编辑'
          this.form = JSON.parse(JSON.stringify(Object.assign({}, row)))
          if (this.form.menutype == '1') {
            this.menutype = '1'
          } else if (this.form.menutype == '2') {
            this.menutype = '2'
          } else {
            this.menutype = '1'
          }
          this.form.meta.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await doEdit(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      // 菜单类型更改
      menuTypeChange(type) {
        const menuType = '1'
        const buttonType = '2'
        // 菜单
        if (menuType === type) {
          this.form.menutype = menuType
          this.form.menu_code = null
        }
        // 按钮
        else if (buttonType === type) {
          this.form.name = null
          this.form.path = null
          this.form.component = null
          this.form.redirect = null
          this.form.meta.icon = null
          this.form.alwaysShow = '0'
          this.form.hidden = '0'
          this.form.menutype = buttonType
        }
      },
    },
  }
</script>
