var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errorLog-container" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleError } },
        [_vm._v(" 点击模拟一个chuzhixinjiayou的错误 ")]
      ),
      _vm.show ? _c("error-test") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }