var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-drag-container" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              _vm.dialogVisible = true
            }
          }
        },
        [_vm._v(" 点击打开可拖拽弹窗 ")]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "drag", rawName: "v-drag" }],
          attrs: {
            "before-close": _vm.handleClose,
            title: "按住我拖拽",
            visible: _vm.dialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v(" 确 定 ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", [
            _vm._v(
              " 爱到尽头 覆水难收 爱悠悠 恨悠悠 为何要到无法挽留 才又想起你的温柔 给我关怀为我解忧 为我凭添许多愁 在深夜无尽等候 独自泪流 独自忍受 多想说声我真的爱你 多想说声对不起你 你哭着说情缘已尽 难再续 难再续 就请你给我多一点点时间 再多一点点问候 "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }