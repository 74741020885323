var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-admin-beautiful-wrapper", class: _vm.classObj },
    [
      _c("vab-layout-" + _vm.theme.layout, {
        tag: "component",
        attrs: {
          collapse: _vm.collapse,
          device: _vm.device,
          "fixed-header": _vm.theme.fixedHeader,
          "show-tabs": _vm.theme.showTabs
        }
      }),
      _c("el-backtop", { attrs: { target: "#app" } }),
      _c("vab-theme-drawer"),
      _c("vab-theme-setting")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }