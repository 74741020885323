var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      on: {
        command: _vm.handleCommand,
        "visible-change": _vm.handleVisibleChange
      },
      scopedSlots: _vm._u([
        {
          key: "dropdown",
          fn: function() {
            return [
              _c(
                "el-dropdown-menu",
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "personalCenter" } },
                    [
                      _c("vab-icon", { attrs: { icon: "user-line" } }),
                      _vm._v(" " + _vm._s(_vm.translateTitle("个人中心")) + " ")
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "logout" } },
                    [
                      _c("vab-icon", {
                        attrs: { icon: "logout-circle-r-line" }
                      }),
                      _vm._v(" " + _vm._s(_vm.translateTitle("退出登录")) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "span",
        { staticClass: "avatar-dropdown" },
        [
          _c("el-avatar", {
            staticClass: "user-avatar",
            attrs: { src: _vm.avatar }
          }),
          _c(
            "div",
            { staticClass: "user-name" },
            [
              _c("span", { staticClass: "hidden-xs-only" }, [
                _vm._v(_vm._s(_vm.username))
              ]),
              _c("vab-icon", {
                staticClass: "vab-dropdown",
                class: { "vab-dropdown-active": _vm.active },
                attrs: { icon: "arrow-down-s-line" }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }