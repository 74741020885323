/**
 * @Description: 数据统计
 * @author keisen
 * @date 2023/6/30
 */
const state = () => ({
  workTime: {},
  serviceTime: {},
  unicomYear: 0,
  unicomMonth: 0,
})
const getters = {
  workTime: (state) => state.workTime,
  serviceTime: (state) => state.serviceTime,
  unicomYear: (state) => state.unicomYear,
  unicomMonth: (state) => state.unicomMonth,
}
const mutations = {
  changeWorkTime(state, newTime) {
    state.workTime = newTime
  },
  changeServiceTime(state, newTime) {
    state.serviceTime = newTime
  },
  changeUnicomYear(state, newTime) {
    state.unicomYear = newTime
  },
  changeUnicomMonth(state, newTime) {
    state.unicomMonth = newTime
  },
}
export default { state, getters, mutations }
