var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "test1-container" },
    [
      _c("el-alert", {
        attrs: {
          closable: false,
          "show-icon": "",
          title: "取值方式：this.params",
          type: "success"
        }
      }),
      _c("vab-json-viewer", {
        attrs: { copyable: "", "expand-depth": 5, sort: "", value: _vm.route }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }