var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button-group",
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "plain" },
              on: { click: _vm.$_zoomIn }
            },
            [_vm._v("放大")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "plain" },
              on: { click: _vm.$_zoomOut }
            },
            [_vm._v("缩小")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "plain" },
              on: { click: _vm.$_zoomReset }
            },
            [_vm._v(" 大小适应 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "plain" },
              on: { click: _vm.$_translateRest }
            },
            [_vm._v(" 定位还原 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "plain" },
              on: { click: _vm.$_reset }
            },
            [_vm._v(" 还原(大小&定位) ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.undoDisable,
                size: "small",
                type: "plain"
              },
              on: { click: _vm.$_undo }
            },
            [_vm._v(" 上一步(ctrl+z) ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.redoDisable,
                size: "small",
                type: "plain"
              },
              on: { click: _vm.$_redo }
            },
            [_vm._v(" 下一步(ctrl+y) ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "plain" },
              on: { click: _vm.$_download }
            },
            [_vm._v(" 下载图片 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "plain" },
              on: { click: _vm.$_catData }
            },
            [_vm._v(" 查看数据 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }