var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "custom-table",
      staticClass: "custom-table-container",
      class: { "vab-fullscreen": _vm.isFullscreen }
    },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "0",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "标题" },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-plus", type: "primary" },
                          on: { click: _vm.handleAdd }
                        },
                        [_vm._v(" 添加 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-delete", type: "danger" },
                          on: { click: _vm.handleDelete }
                        },
                        [_vm._v(" 删除 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-right-panel",
            [
              _c(
                "div",
                { staticClass: "stripe-panel" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.stripe,
                        callback: function($$v) {
                          _vm.stripe = $$v
                        },
                        expression: "stripe"
                      }
                    },
                    [_vm._v("斑马纹")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "border-panel" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.border,
                        callback: function($$v) {
                          _vm.border = $$v
                        },
                        expression: "border"
                      }
                    },
                    [_vm._v("边框（可拉伸列）")]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 10px 10px 0 !important" },
                  attrs: { type: "primary" },
                  on: { click: _vm.clickFullScreen }
                },
                [
                  _c("vab-icon", {
                    attrs: {
                      icon: _vm.isFullscreen
                        ? "fullscreen-exit-fill"
                        : "fullscreen-fill"
                    }
                  }),
                  _vm._v(" 表格全屏 ")
                ],
                1
              ),
              _c(
                "el-popover",
                {
                  ref: "popover",
                  attrs: {
                    "popper-class": "custom-table-checkbox",
                    trigger: "hover"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function() {
                        return [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                margin: "0 10px 10px 0 !important"
                              },
                              attrs: { type: "primary" }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "line-height" }
                              }),
                              _vm._v(" 表格尺寸 ")
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.lineHeight,
                        callback: function($$v) {
                          _vm.lineHeight = $$v
                        },
                        expression: "lineHeight"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "medium" } }, [
                        _vm._v("大")
                      ]),
                      _c("el-radio", { attrs: { label: "small" } }, [
                        _vm._v("中")
                      ]),
                      _c("el-radio", { attrs: { label: "mini" } }, [
                        _vm._v("小")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "custom-table-checkbox",
                    trigger: "hover"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function() {
                        return [
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 0 10px 0 !important" },
                              attrs: {
                                icon: "el-icon-setting",
                                type: "primary"
                              }
                            },
                            [_vm._v(" 可拖拽列设置 ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.checkList,
                        callback: function($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList"
                      }
                    },
                    [
                      _c(
                        "vab-draggable",
                        _vm._b(
                          { attrs: { list: _vm.columns } },
                          "vab-draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(_vm.columns, function(item, index) {
                          return _c(
                            "div",
                            { key: item + index },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "drag-drop-line" }
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    disabled: item.disableCheck === true,
                                    label: item.label
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          attrs: {
            border: _vm.border,
            data: _vm.list,
            height: _vm.height,
            size: _vm.lineHeight,
            stripe: _vm.stripe
          },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              "show-overflow-tooltip": "",
              width: "95"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var $index = ref.$index
                  return [_vm._v(" " + _vm._s($index + 1) + " ")]
                }
              }
            ])
          }),
          _vm._l(_vm.finallyColumns, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                sortable: item.sortable,
                width: item.width
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        item.label === "评级"
                          ? _c(
                              "span",
                              [
                                _c("el-rate", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: row.rate,
                                    callback: function($$v) {
                                      _vm.$set(row, "rate", $$v)
                                    },
                                    expression: "row.rate"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row[item.prop]))])
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "85"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("table-edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }