var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme.showFullScreen
    ? _c("vab-icon", {
        attrs: {
          icon: _vm.isFullscreen ? "fullscreen-exit-fill" : "fullscreen-fill"
        },
        on: { click: _vm.click }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }