var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vab-layout-vertical",
      class: {
        fixed: _vm.fixedHeader,
        "no-tabs-bar": !_vm.showTabs
      }
    },
    [
      _c("vab-side-bar"),
      _vm.device === "mobile" && !_vm.collapse
        ? _c("div", {
            staticClass: "v-modal",
            on: { click: _vm.handleFoldSideBar }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "vab-main",
          class: {
            "is-collapse-main": _vm.collapse
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "vab-layout-header",
              class: {
                "fixed-header": _vm.fixedHeader
              }
            },
            [
              _c("vab-nav"),
              _c("vab-tabs", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTabs,
                    expression: "showTabs"
                  }
                ]
              })
            ],
            1
          ),
          _c("vab-app-main")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }