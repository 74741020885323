var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "destroy-on-close": true,
        title: _vm.dialogTitle,
        visible: _vm.dialogFormVisible,
        width: "500px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-col",
        [
          _c("el-input", {
            attrs: { placeholder: "输入关键字过滤" },
            model: {
              value: _vm.filterText,
              callback: function($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText"
            }
          }),
          _c("el-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            ref: "permsTree",
            attrs: {
              "check-on-click-node": true,
              data: _vm.list,
              "default-expanded-keys": [],
              "expand-on-click-node": false,
              "filter-node-method": _vm.filterNode,
              "node-key": "id",
              "show-checkbox": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var data = ref.data
                  return _c("span", { staticClass: "vab-custom-tree-node" }, [
                    _c(
                      "span",
                      { staticClass: "vab-tree-item" },
                      [
                        data.menutype == "1"
                          ? _c("el-tag", [_vm._v("菜单")])
                          : _vm._e(),
                        data.menutype == "2"
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("按钮")
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(data.meta.title) + " ")
                      ],
                      1
                    )
                  ])
                }
              }
            ])
          }),
          _vm._v(" " + _vm._s(_vm.data) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }