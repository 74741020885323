var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "authorization",
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("vab-icon", { attrs: { icon: "bar-chart-2-line" } }),
              _vm._v(" 授权数 "),
              _c(
                "el-tag",
                { staticClass: "card-header-tag", attrs: { type: "warning" } },
                [_vm._v("周")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("vab-chart", {
        attrs: {
          "init-options": _vm.initOptions,
          option: _vm.option,
          theme: "vab-echarts-theme"
        }
      }),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "span",
          [
            _vm._v(" 授权数: "),
            _c("vab-count", {
              attrs: {
                decimals: _vm.countConfig.decimals,
                duration: _vm.countConfig.duration,
                "end-val": _vm.countConfig.endVal,
                prefix: _vm.countConfig.prefix,
                separator: _vm.countConfig.separator,
                "start-val": _vm.countConfig.startVal,
                suffix: _vm.countConfig.suffix
              }
            }),
            _c(
              "el-tag",
              { staticClass: "card-footer-tag", attrs: { type: "success" } },
              [_vm._v("倒计时 " + _vm._s(_vm.n) + "s")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }