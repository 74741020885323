var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("vab-icon", { attrs: { icon: "github-line" } }),
              _vm._v(" 我的开源项目 "),
              _c("el-tag", { staticClass: "card-header-tag" }, [
                _vm._v("爱我别走")
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { lg: 12, md: 12, sm: 24, xl: 12, xs: 24 } },
            [
              _c(
                "div",
                {
                  staticClass: "project-card",
                  style: "background-image:url(" + item.image + ")",
                  on: {
                    click: function($event) {
                      return _vm.handleOpenWindow(item.url)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "project-card-title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "project-card-description" }, [
                    _vm._v(_vm._s(item.description))
                  ])
                ]
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }