var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contextmenu-container" },
    [
      _c(
        "vab-contextmenu",
        { ref: "contextmenu" },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "vab-contextmenu-item",
            {
              key: index,
              attrs: { disabled: item.disabled },
              on: {
                click: function($event) {
                  return _vm.handleMessage(item.title)
                }
              }
            },
            [
              _c("vab-icon", { attrs: { icon: item.icon } }),
              _vm._v(" " + _vm._s(item.title) + " ")
            ],
            1
          )
        }),
        1
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "contextmenu",
              rawName: "v-contextmenu:contextmenu",
              arg: "contextmenu"
            }
          ]
        },
        [_vm._v("右键点击此按钮")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }