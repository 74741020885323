var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        "append-to-body": "",
        "custom-class": "vab-drawer",
        direction: "rtl",
        size: "280px",
        title: _vm.translateTitle("主题配置"),
        visible: _vm.drawerVisible
      },
      on: {
        "update:visible": function($event) {
          _vm.drawerVisible = $event
        }
      }
    },
    [
      _c(
        "el-scrollbar",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-position": "left", model: _vm.theme }
            },
            [
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.translateTitle("布局")) + " "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.translateTitle(
                                  "布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局"
                                ),
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "question-line" }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.device === "mobile" },
                      model: {
                        value: _vm.theme.layout,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "layout", $$v)
                        },
                        expression: "theme.layout"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "column",
                        attrs: {
                          label: _vm.translateTitle("分栏"),
                          value: "column"
                        }
                      }),
                      _c("el-option", {
                        key: "comprehensive",
                        attrs: {
                          label: _vm.translateTitle("综合"),
                          value: "comprehensive"
                        }
                      }),
                      _c("el-option", {
                        key: "vertical",
                        attrs: {
                          label: _vm.translateTitle("纵向"),
                          value: "vertical"
                        }
                      }),
                      _c("el-option", {
                        key: "horizontal",
                        attrs: {
                          label: _vm.translateTitle("横向"),
                          value: "horizontal"
                        }
                      }),
                      _c("el-option", {
                        key: "common",
                        attrs: {
                          label: _vm.translateTitle("常规"),
                          value: "common"
                        }
                      }),
                      _c("el-option", {
                        key: "float",
                        attrs: {
                          label: _vm.translateTitle("浮动"),
                          value: "float"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("主题") } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.setTheme },
                      model: {
                        value: _vm.theme.themeName,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "themeName", $$v)
                        },
                        expression: "theme.themeName"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "blue-black",
                        attrs: {
                          label: _vm.translateTitle("蓝黑"),
                          value: "blue-black"
                        }
                      }),
                      _c("el-option", {
                        key: "blue-white",
                        attrs: {
                          label: _vm.translateTitle("蓝白"),
                          value: "blue-white"
                        }
                      }),
                      _c("el-option", {
                        key: "green-black",
                        attrs: {
                          label: _vm.translateTitle("绿黑"),
                          value: "green-black"
                        }
                      }),
                      _c("el-option", {
                        key: "green-white",
                        attrs: {
                          label: _vm.translateTitle("绿白"),
                          value: "green-white"
                        }
                      }),
                      _c("el-option", {
                        key: "red-black",
                        attrs: {
                          label: _vm.translateTitle("红黑（非内置）"),
                          value: "red-black"
                        }
                      }),
                      _c("el-option", {
                        key: "red-white",
                        attrs: {
                          label: _vm.translateTitle("红白（非内置）"),
                          value: "red-white"
                        }
                      }),
                      _c("el-option", {
                        key: "ocean",
                        attrs: {
                          label: _vm.translateTitle("渐变"),
                          value: "ocean"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "vab-item-custom",
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.translateTitle("菜单背景")) + " "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.translateTitle(
                                  "支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局"
                                ),
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "question-line" }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled:
                          _vm.theme.layout === "float" ||
                          _vm.theme.layout === "horizontal"
                      },
                      on: { change: _vm.setTheme },
                      model: {
                        value: _vm.theme.background,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "background", $$v)
                        },
                        expression: "theme.background"
                      }
                    },
                    [
                      _c("el-radio-button", {
                        staticClass: "none",
                        attrs: { label: "none" }
                      }),
                      _c("el-radio-button", {
                        staticClass: "vab-background",
                        attrs: { label: "vab-background" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("标签") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.theme.showTabs,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showTabs", $$v)
                      },
                      expression: "theme.showTabs"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.translateTitle("标签图标")) + " "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.translateTitle("标签开启时生效"),
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "question-line" }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("el-switch", {
                    attrs: { disabled: !_vm.theme.showTabs },
                    model: {
                      value: _vm.theme.showTabsBarIcon,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showTabsBarIcon", $$v)
                      },
                      expression: "theme.showTabsBarIcon"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.translateTitle("标签风格")) + " "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.translateTitle("标签开启时生效"),
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "question-line" }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: !_vm.theme.showTabs },
                      model: {
                        value: _vm.theme.tabsBarStyle,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "tabsBarStyle", $$v)
                        },
                        expression: "theme.tabsBarStyle"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "card",
                        attrs: {
                          label: _vm.translateTitle("卡片"),
                          value: "card"
                        }
                      }),
                      _c("el-option", {
                        key: "smart",
                        attrs: {
                          label: _vm.translateTitle("灵动"),
                          value: "smart"
                        }
                      }),
                      _c("el-option", {
                        key: "smooth",
                        attrs: {
                          label: _vm.translateTitle("圆滑"),
                          value: "smooth"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.translateTitle("分栏风格")) + " "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.translateTitle("分栏布局时生效"),
                                effect: "dark",
                                placement: "top"
                              }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "question-line" }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.theme.layout !== "column" },
                      model: {
                        value: _vm.theme.columnStyle,
                        callback: function($$v) {
                          _vm.$set(_vm.theme, "columnStyle", $$v)
                        },
                        expression: "theme.columnStyle"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "vertical",
                        attrs: {
                          label: _vm.translateTitle("纵向"),
                          value: "vertical"
                        }
                      }),
                      _c("el-option", {
                        key: "horizontal",
                        attrs: {
                          label: _vm.translateTitle("横向"),
                          value: "horizontal"
                        }
                      }),
                      _c("el-option", {
                        key: "card",
                        attrs: {
                          label: _vm.translateTitle("卡片"),
                          value: "card"
                        }
                      }),
                      _c("el-option", {
                        key: "arrow",
                        attrs: {
                          label: _vm.translateTitle("箭头"),
                          value: "arrow"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("头部固定") } },
                [
                  _c("el-switch", {
                    attrs: { disabled: _vm.theme.layout === "common" },
                    model: {
                      value: _vm.theme.fixedHeader,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "fixedHeader", $$v)
                      },
                      expression: "theme.fixedHeader"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("国际化") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.theme.showLanguage,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showLanguage", $$v)
                      },
                      expression: "theme.showLanguage"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("进度条") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.theme.showProgressBar,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showProgressBar", $$v)
                      },
                      expression: "theme.showProgressBar"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("刷新") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.theme.showRefresh,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showRefresh", $$v)
                      },
                      expression: "theme.showRefresh"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("搜索") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.theme.showSearch,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showSearch", $$v)
                      },
                      expression: "theme.showSearch"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("通知") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.theme.showNotice,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showNotice", $$v)
                      },
                      expression: "theme.showNotice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.translateTitle("全屏") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.theme.showFullScreen,
                      callback: function($$v) {
                        _vm.$set(_vm.theme, "showFullScreen", $$v)
                      },
                      expression: "theme.showFullScreen"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-drawer__footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSaveTheme } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("保存")) + " ")]
          ),
          _c("el-button", { on: { click: _vm.setDefaultTheme } }, [
            _vm._v(" " + _vm._s(_vm.translateTitle("恢复默认")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }