<template>
  <div class="role-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button
          v-if="$perms('setting_role_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <el-button
          v-if="$perms('setting_role_setPerms')"
          :disabled="selectRows.length !== 1"
          icon="el-icon-menu"
          type="success"
          @click="setPerms"
        >
          设置权限
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.roleName"
              clearable
              placeholder="请输入角色"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />

      <el-table-column
        align="center"
        label="id"
        prop="rid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="角色名"
        prop="roleName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="描述"
        prop="Description"
        show-overflow-tooltip
      />
      <el-table-column label="操作" show-overflow-tooltip width="185">
        <template #default="{ row }">
          <el-button
            v-if="$perms('setting_role_update')"
            type="primary"
            @click="handleEdit(row)"
          >
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button
            v-if="$perms('setting_role_delete')"
            type="danger"
            @click="handleDelete(row)"
          >
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <perms ref="perms" />
  </div>
</template>

<script>
  import { doDelete, getList } from '@/api/roleManagement'
  import Edit from './components/RoleManagementEdit'
  import Perms from './components/RoleManagementPerms'

  export default {
    name: 'RoleManagement',
    components: { Edit, Perms },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          role: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setPerms() {
        let row = this.selectRows[0]
        this.$refs['perms'].showPerms(row)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.rid) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.rid) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ rid: row.rid })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
