var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "print-container", staticClass: "print-container" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.print("vab-print-image")
            }
          }
        },
        [
          _c("vab-icon", { attrs: { icon: "printer-line" } }),
          _vm._v(" 打印图片 ")
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.print("vab-print-table")
            }
          }
        },
        [
          _c("vab-icon", { attrs: { icon: "printer-line" } }),
          _vm._v(" 打印表格 ")
        ],
        1
      ),
      _c("img", {
        ref: "vab-print-image",
        staticStyle: { display: "block", width: "520px" },
        attrs: {
          src: "https://gitee.com/chu1204505056/image/raw/master/fapiao.png"
        }
      }),
      _c("br"),
      _c(
        "el-table",
        {
          ref: "vab-print-table",
          staticStyle: { width: "520px" },
          attrs: { data: _vm.tableData }
        },
        [
          _c("el-table-column", {
            attrs: { label: "姓名", prop: "name", width: "120px" }
          }),
          _c("el-table-column", { attrs: { label: "地址", prop: "address" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }