var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    {
      staticClass: "vab-side-bar",
      class: {
        "is-collapse": _vm.collapse,
        "side-bar-common": _vm.layout === "common"
      }
    },
    [
      _vm.layout === "vertical" ||
      _vm.layout === "comprehensive" ||
      _vm.layout === "float"
        ? _c("vab-logo")
        : _vm._e(),
      _c(
        "el-menu",
        {
          attrs: {
            "active-text-color": _vm.variables["menu-color-active"],
            "background-color": _vm.variables["menu-background"],
            collapse: _vm.collapse,
            "collapse-transition": false,
            "default-active": _vm.activeMenu,
            "default-openeds": _vm.defaultOpeneds,
            "menu-trigger": "click",
            mode: "vertical",
            "text-color": _vm.variables["menu-color"],
            "unique-opened": _vm.uniqueOpened
          }
        },
        [
          _vm._l(_vm.handleRoutes, function(route, index) {
            return [
              route.meta && !route.meta.hidden
                ? _c("vab-menu", {
                    key: index + route.name,
                    attrs: { item: route }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }