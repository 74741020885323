var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "target" },
    [
      _c(
        "el-card",
        {
          staticClass: "target-card1",
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("vab-icon", { attrs: { icon: "body-scan-line" } }),
                  _vm._v(" 小目标 ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("vab-chart", {
            staticClass: "target-echart1",
            attrs: {
              "init-options": _vm.initOptions,
              option: _vm.option,
              theme: "vab-echarts-theme"
            }
          })
        ],
        1
      ),
      _c(
        "el-card",
        {
          staticClass: "target-card2",
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "span",
                    [
                      _c("vab-icon", { attrs: { icon: "money-cny-box-line" } }),
                      _vm._v(" 销售额 ")
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("vab-chart", {
            staticClass: "target-echart2",
            attrs: {
              "init-options": _vm.initOptions,
              option: _vm.option2,
              theme: "vab-echarts-theme"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }