<template>
  <el-dialog
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :title="dialogTitle"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-col>
      <el-input v-model="filterText" placeholder="输入关键字过滤" />

      <el-tree
        ref="permsTree"
        v-loading="loading"
        :check-on-click-node="true"
        :data="list"
        :default-expanded-keys="[]"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        node-key="id"
        show-checkbox
      >
        <span slot-scope="{ data }" class="vab-custom-tree-node">
          <span class="vab-tree-item">
            <!-- 菜单 -->
            <el-tag v-if="data.menutype == '1'">菜单</el-tag>
            <el-tag v-if="data.menutype == '2'" type="warning">按钮</el-tag>

            {{ data.meta.title }}
          </span>
        </span>
      </el-tree>
      {{ data }}
    </el-col>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { doGetPerms, doSetPerms } from '@/api/roleManagement'
  // import { getList } from '@/api/router'
  import { getTree } from '@/api/menuManagement'
  import { isNull } from '@/utils/validate'

  export default {
    name: 'RoleManagementEdit',
    data() {
      return {
        form: {},
        rules: {
          role: [{ required: true, trigger: 'blur', message: '请输入角色码' }],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
        filterText: '',
        dialogTitle: '设置权限',
        loading: true,
      }
    },
    watch: {
      filterText(val) {
        this.$refs.permsTree.filter(val)
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      showPerms(row) {
        this.rid = row.rid
        // 加载数据
        this.fetchData()
        this.dialogFormVisible = true
      },
      close() {
        // this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async fetchData() {
        this.loading = true
        let that = this
        const { data } = await getTree()

        const checkedData = await doGetPerms({ rid: that.rid })
        this.list = data.list
        if (!isNull(checkedData) && !isNull(checkedData.data)) {
          that.$nextTick(() => {
            const checkArray = checkedData.data
            if (checkArray != null && checkArray.length > 0) {
              checkArray.forEach((id) => {
                if (id.menu__id != null && id.menu__id > 0) {
                  let node = that.$refs.permsTree.getNode(id.menu__id)
                  if (node && node.isLeaf) {
                    that.$refs.permsTree.setChecked(node, true)
                  }
                }
              })
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      },
      async save() {
        let nodeIds = this.$refs.permsTree
          .getCheckedKeys()
          .concat(this.$refs.permsTree.getHalfCheckedKeys())
        const { msg } = await doSetPerms({
          rid: this.rid,
          permsIds: this.unique(nodeIds).toString(),
        })
        this.$baseMessage(msg, 'success', 'vab-hey-message-success')
        this.$emit('fetch-data')
        this.close()
      },
      // 节点过滤操作
      filterNode(value, data) {
        if (!value) return true
        return data.meta.title.indexOf(value) !== -1
      },

      // ===================
      // 数组去重
      unique(arr) {
        return Array.from(new Set(arr))
      },
    },
  }
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    padding: $base-padding;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }
</style>
