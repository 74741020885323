var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { lg: 14, md: 11, sm: 24, xl: 14, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符")
            ])
          ]),
          _c(
            "el-col",
            { attrs: { lg: 9, md: 12, sm: 24, xl: 9, xs: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "registerForm",
                  staticClass: "register-form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.registerRules,
                    size: "mini"
                  }
                },
                [
                  _c("div", { staticClass: "title-tips" }, [
                    _vm._v(_vm._s(_vm.translateTitle("注册")))
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: {
                          "auto-complete": "off",
                          placeholder: _vm.translateTitle("请输入用户名"),
                          type: "text"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function() {
                              return [
                                _c("vab-icon", { attrs: { icon: "user-line" } })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.username,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "11",
                          placeholder: _vm.translateTitle("请输入手机号"),
                          "show-word-limit": "",
                          type: "text"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function() {
                              return [
                                _c("vab-icon", {
                                  attrs: { icon: "smartphone-line" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { position: "relative" },
                      attrs: { prop: "phoneCode" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.translateTitle("请输入手机验证码"),
                          type: "text"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function() {
                              return [
                                _c("vab-icon", {
                                  attrs: { icon: "barcode-box-line" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.phoneCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "phoneCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.phoneCode"
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "phone-code",
                          attrs: { disabled: _vm.isGetPhone, type: "primary" },
                          on: { click: _vm.getPhoneCode }
                        },
                        [_vm._v(" " + _vm._s(_vm.phoneCode) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "new-password",
                          placeholder: _vm.translateTitle("请输入密码"),
                          type: "password"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function() {
                              return [
                                _c("vab-icon", { attrs: { icon: "lock-line" } })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "register-btn",
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleRegister.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.translateTitle("注册")) + " ")]
                      ),
                      _c("router-link", { attrs: { to: "/login" } }, [
                        _c("div", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v(" " + _vm._s(_vm.translateTitle("登录")) + " ")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { attrs: { lg: 1, md: 1, sm: 24, xl: 1, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }