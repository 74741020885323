var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-container" }, [
    _c(
      "div",
      { staticClass: "error-content" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { lg: 12, md: 12, sm: 24, xl: 12, xs: 24 } },
              [
                _c(
                  "div",
                  { staticClass: "pic-error" },
                  [
                    _c("el-image", {
                      staticClass: "pic-error-parent",
                      attrs: { src: require("@/assets/error_images/404.png") }
                    }),
                    _c("el-image", {
                      staticClass: "pic-error-child left",
                      attrs: { src: require("@/assets/error_images/cloud.png") }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-col",
              { attrs: { lg: 12, md: 12, sm: 24, xl: 12, xs: 24 } },
              [
                _c(
                  "div",
                  { staticClass: "bullshit" },
                  [
                    _c("div", { staticClass: "bullshit-oops" }, [
                      _vm._v(_vm._s(_vm.oops))
                    ]),
                    _c("div", { staticClass: "bullshit-headline" }, [
                      _vm._v(_vm._s(_vm.headline))
                    ]),
                    _c("div", { staticClass: "bullshit-info" }, [
                      _vm._v(_vm._s(_vm.info))
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "bullshit-return-home",
                        attrs: { to: "/" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.jumpTime) +
                            "s " +
                            _vm._s(_vm.btn) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }