var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("vab-icon", { attrs: { icon: "send-plane-2-line" } }),
              _vm._v(" 计划 "),
              _c(
                "el-tag",
                { staticClass: "card-header-tag", attrs: { type: "success" } },
                [_vm._v(" 祝用框架的小伙伴都能住上别墅，开上保时捷 ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, height: "283px", "row-key": "title" } },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "拖拽", width: "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  return [
                    _c("vab-icon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "drag-move-line" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { width: "20px" } }),
          _c("el-table-column", {
            attrs: { label: "目标", prop: "title", width: "230px" }
          }),
          _c("el-table-column", {
            attrs: { label: "进度", width: "220px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-progress", {
                      attrs: { color: row.color, percentage: row.percentage }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { width: "50px" } }),
          _c("el-table-column", {
            attrs: { label: "完成时间", prop: "endTIme" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }