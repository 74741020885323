var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "player-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
                [
                  _c(
                    "el-card",
                    {
                      attrs: { shadow: "hover" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [_vm._v("常规视频播放(可配置弹幕)")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        861329851
                      )
                    },
                    [
                      _c("vab-player-mp4", {
                        attrs: { config: _vm.mp4Config },
                        on: {
                          player: function($event) {
                            _vm.$vabPlayerMp4 = $event
                          }
                        }
                      }),
                      _c(
                        "el-form",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { inline: true, model: _vm.form }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "弹幕" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "弹幕" },
                                model: {
                                  value: _vm.form.danmu,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "danmu", $$v)
                                  },
                                  expression: "form.danmu"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c("el-button", { on: { click: _vm.onSubmit } }, [
                                _vm._v("发射弹幕")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
                [
                  _c(
                    "el-card",
                    {
                      attrs: { shadow: "hover" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [_vm._v("Hls推流、m3u8播放(可配置弹幕)")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        4212740316
                      )
                    },
                    [
                      _c("vab-player-hls", {
                        attrs: { config: _vm.hlsConfig },
                        on: {
                          player: function($event) {
                            _vm.$vabPlayerHls = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }