var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        "el-card",
        { staticClass: "tabs-card", attrs: { shadow: "hover" } },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "配送管理", name: "first" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, height: "280" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "日期", prop: "date" }
                      }),
                      _c(
                        "el-table-column",
                        { attrs: { label: "配送信息" } },
                        [
                          _c("el-table-column", {
                            attrs: { label: "姓名", prop: "name" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "省份", prop: "province" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "市区", prop: "city" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "地址", prop: "address" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "邮编", prop: "zip" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "区域管理", name: "second" } },
                [_vm._v("敬请期待")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }