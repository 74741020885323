var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme.showThemeSetting
    ? _c("ul", { staticClass: "vab-theme-setting" }, [
        _c("li", { on: { click: _vm.handleOpenTheme } }, [
          _c(
            "a",
            [
              _c("vab-icon", { attrs: { icon: "brush-2-line" } }),
              _c("p", [_vm._v(_vm._s(_vm.translateTitle("主题配置")))])
            ],
            1
          )
        ]),
        _c("li", { on: { click: _vm.randomTheme } }, [
          _c(
            "a",
            [
              _c("vab-icon", { attrs: { icon: "apps-line" } }),
              _c("p", [_vm._v(_vm._s(_vm.translateTitle("随机换肤")))])
            ],
            1
          )
        ]),
        _c("li", { on: { click: _vm.removeLocalStorage } }, [
          _c(
            "a",
            [
              _c("vab-icon", { attrs: { icon: "delete-bin-4-line" } }),
              _c("p", [
                _vm._v(" " + _vm._s(_vm.translateTitle("清理缓存")) + " ")
              ])
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }