var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cssfx-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.effects, function(item, index) {
          return _c(
            "el-col",
            { key: item.name, attrs: { lg: 4, md: 4, sm: 12, xl: 3, xs: 12 } },
            [
              _c(
                "div",
                { staticClass: "cssfx-container-card" },
                [_c(index, { tag: "component" })],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }