var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 12, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(" 常规风格 "),
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "card-header-radio",
                              model: {
                                value: _vm.reverse1,
                                callback: function($$v) {
                                  _vm.reverse1 = $$v
                                },
                                expression: "reverse1"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("倒序")
                              ]),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("正序")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-timeline",
                    { attrs: { reverse: _vm.reverse1 } },
                    _vm._l(_vm.activities, function(item, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          attrs: {
                            color: item.color,
                            timestamp: item.timestamp
                          },
                          scopedSlots: _vm._u(
                            [
                              !item.color
                                ? {
                                    key: "dot",
                                    fn: function() {
                                      var _obj
                                      return [
                                        item.icon
                                          ? _c("vab-icon", {
                                              attrs: { icon: item.icon }
                                            })
                                          : _vm._e(),
                                        item.waver
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "vab-dot",
                                                class:
                                                  ((_obj = {}),
                                                  (_obj[
                                                    "vab-dot-" + item.waver
                                                  ] = true),
                                                  _obj)
                                              },
                                              [_c("span")]
                                            )
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        },
                        [
                          item.card
                            ? _c("el-card", { attrs: { shadow: "hover" } }, [
                                _vm._v(" " + _vm._s(item.content) + " ")
                              ])
                            : [_vm._v(" " + _vm._s(item.content) + " ")]
                        ],
                        2
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 12, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(" 卡片风格 "),
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "card-header-radio",
                              model: {
                                value: _vm.reverse2,
                                callback: function($$v) {
                                  _vm.reverse2 = $$v
                                },
                                expression: "reverse2"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("倒序")
                              ]),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("正序")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "el-timeline",
                    { attrs: { reverse: _vm.reverse2 } },
                    _vm._l(_vm.activities, function(item, index) {
                      var _obj
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          attrs: {
                            color: item.color,
                            timestamp: item.timestamp
                          },
                          scopedSlots: _vm._u(
                            [
                              !item.color
                                ? {
                                    key: "dot",
                                    fn: function() {
                                      var _obj
                                      return [
                                        item.icon
                                          ? _c("vab-icon", {
                                              attrs: { icon: item.icon }
                                            })
                                          : _vm._e(),
                                        item.waver
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "vab-dot",
                                                class:
                                                  ((_obj = {}),
                                                  (_obj[
                                                    "vab-dot-" + item.waver
                                                  ] = true),
                                                  _obj)
                                              },
                                              [_c("span")]
                                            )
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vab-info-card",
                              class:
                                ((_obj = {}),
                                (_obj["vab-info-card-" + item.cardType] = true),
                                _obj)
                            },
                            [_vm._v(" " + _vm._s(item.content) + " ")]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }