var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-selector-container" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "icon-selector-popper",
            trigger: "hover",
            width: "292"
          },
          scopedSlots: _vm._u([
            {
              key: "reference",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    [
                      _c("vab-icon", { attrs: { icon: _vm.icon } }),
                      _vm._v(" 图标选择器 "),
                      _c("vab-icon", { attrs: { icon: "arrow-down-s-line" } })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("vab-icon-selector", { on: { "handle-icon": _vm.handleIcon } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }