var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    { attrs: { "tab-position": "left" } },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "添加动作" } },
        _vm._l(_vm.nodeList, function(item) {
          return _c(
            "div",
            { key: item.type },
            [
              _c(
                "el-button",
                {
                  staticClass: "add-node-btn",
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$_addNode(item)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            ],
            1
          )
        }),
        0
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "添加组" } },
        [
          _c(
            "el-button",
            {
              staticClass: "add-node-btn",
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.$_addTempalte }
            },
            [_vm._v(" 模板 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }