var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-time-picker-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_c("span", [_vm._v("日期和时间点")])]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-date-picker", {
            attrs: { placeholder: "选择日期时间", type: "datetime" },
            model: {
              value: _vm.value1,
              callback: function($$v) {
                _vm.value1 = $$v
              },
              expression: "value1"
            }
          })
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_c("span", [_vm._v("日期和时间范围")])]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-date-picker", {
            attrs: {
              "end-placeholder": "结束日期",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              type: "datetimerange"
            },
            model: {
              value: _vm.value2,
              callback: function($$v) {
                _vm.value2 = $$v
              },
              expression: "value2"
            }
          })
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_c("span", [_vm._v("默认的起始与结束时刻")])]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-date-picker", {
            attrs: {
              align: "right",
              "default-time": ["12:00:00", "08:00:00"],
              "end-placeholder": "结束日期",
              "start-placeholder": "开始日期",
              type: "datetimerange"
            },
            model: {
              value: _vm.value3,
              callback: function($$v) {
                _vm.value3 = $$v
              },
              expression: "value3"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }