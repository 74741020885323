var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c(
        "vab-query-form",
        { staticClass: "page-header" },
        [
          _c(
            "vab-query-form-top-panel",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "50px",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "区域" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择活动区域" },
                          model: {
                            value: _vm.queryForm.area,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "area", $$v)
                            },
                            expression: "queryForm.area"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "区域一", value: "shanghai" }
                          }),
                          _c("el-option", {
                            attrs: { label: "区域二", value: "beijing" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          placeholder: "选择日期时间",
                          type: "datetime"
                        },
                        model: {
                          value: _vm.queryForm.datetime,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "datetime", $$v)
                          },
                          expression: "queryForm.datetime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "名称" },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { lg: 8, md: 8, sm: 8, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: {
                    "body-style": { padding: "0px", position: "static" },
                    shadow: "hover"
                  }
                },
                [
                  _c("vab-magnifier", {
                    staticStyle: { width: "100%", height: "228px" },
                    attrs: { type: "circle", url: item.img, width: 150 }
                  }),
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "card-description" }, [
                    _vm._v(_vm._s(item.description))
                  ]),
                  _c("div", { staticClass: "card-datetime" }, [
                    _vm._v(_vm._s(item.datetime))
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }