var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "count-container" },
    [
      _c(
        "div",
        { staticClass: "count-container-text" },
        [
          _vm.show
            ? _c("vab-count", {
                attrs: {
                  decimals: _vm.form.decimals,
                  duration: _vm.form.duration,
                  "end-val": _vm.form.endVal,
                  prefix: _vm.form.prefix,
                  separator: _vm.form.separator,
                  "start-val": _vm.form.startVal,
                  suffix: _vm.form.suffix
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "起始值" } },
            [
              _c("el-input-number", {
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.startVal,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "startVal", $$v)
                  },
                  expression: "form.startVal"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "最终值" } },
            [
              _c("el-input-number", {
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.endVal,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "endVal", $$v)
                  },
                  expression: "form.endVal"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "持续时间" } },
            [
              _c("el-input-number", {
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.duration,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "duration", $$v)
                  },
                  expression: "form.duration"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "小数位数" } },
            [
              _c("el-input-number", {
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.decimals,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "decimals", $$v)
                  },
                  expression: "form.decimals"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "前缀" } },
            [
              _c("el-input", {
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.prefix,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "prefix", $$v)
                  },
                  expression: "form.prefix"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "后缀" } },
            [
              _c("el-input", {
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.suffix,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "suffix", $$v)
                  },
                  expression: "form.suffix"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }