var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { attrs: { lg: _vm.span, md: 24, sm: 24, xl: _vm.span, xs: 24 } },
    [_c("div", { staticClass: "right-panel" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }